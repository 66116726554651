import styled from "styled-components";

// Services
import intl from "../../services/intl";

// Utils
import { Color, rem, responsive, Font, rgba } from "../../utils/style";
import { Icons } from "../../utils/react-svg";
import classNames from "classnames/bind";

// Components
import Container from "../Container";
import Row from "../Row";

// Styled Elements
const Column = styled.div.attrs({
  className: "col-12 col-sm-8 offset-sm-2",
})`
  text-align: center;
  padding: 80px 20px;

  ${responsive.sm`
    padding: 80px 3px;
  `};

  ${responsive.md`
    padding: 120px 4px;
  `};

  > h1 {
    text-align: center;
    font-size: ${rem(30)};
    line-height: ${rem(36)};
    font-weight: 500;
    letter-spacing: -0.4px;
    margin: 0 0 16px;

    ${responsive.md`
      font-size: ${rem(48)};
      line-height: ${rem(54)};
      letter-spacing: -1.4px;
      margin-bottom: 24px;
    `};
  }

  > p:first-of-type {
    ${Font.dutch};
    text-align: center;
    font-size: ${rem(16)};
    line-height: ${rem(26)};
    font-weight: 300;
    margin: 0;

    ${responsive.md`
      font-size: ${rem(18)};
      line-height: ${rem(28)};
    `};
  }
`;

const Table = styled.table`
  margin: 40px auto 32px;

  ${responsive.md`
    margin: 56px auto 48px;
  `};

  thead {
    tr {
      th {
        position: relative;
        width: 48px;
        height: 93px;
        border-bottom: 2px solid ${Color.ritualBlue};

        &:first-child {
          width: auto;
        }

        ${responsive.md`
          width: 86px;
        `};
      }
    }
  }

  tbody {
    tr {
      td {
        border-bottom: 1px solid ${rgba(Color.ritualBlue, 0.16)};
        background-color: ${Color.white};
        padding: 24px 16px 24px 0;
        text-align: left;

        ${responsive.sm`
          padding-right: 32px;
        `};

        ${responsive.md`
          padding-right: 40px;
        `};

        ${responsive.lg`
          padding-right: 120px;
        `};

        &.true,
        &.false {
          text-align: center;
          padding: 32px 16px;
          vertical-align: top;

          ${responsive.md`
            padding: 24px;
          `};

          svg {
            width: 18px;
            height: 17px;

            ${responsive.md`
              width: 26px;
              height: 24px;
            `};
          }
        }

        &.true {
          background-color: #ffeeaa;
        }

        &.false {
          background-color: rgba(242, 241, 245, 0.56);
        }

        h2 {
          font-size: ${rem(18)};
          line-height: ${rem(28)};
          font-weight: 500;
          margin: 0 0 8px;

          ${responsive.md`
            font-size: ${rem(20)};
            line-height: ${rem(30)};
          `};
        }

        p {
          font-size: ${rem(14)};
          line-height: ${rem(20)};
          font-weight: 300;
          margin: 0;

          ${responsive.md`
            font-size: ${rem(16)};
            line-height: ${rem(22)};
          `};
        }
      }

      &:last-child {
        td {
          border-bottom: 2px solid ${Color.ritualBlue};
        }
      }
    }
  }
`;

const ColumnLabel = styled.span`
  position: absolute;
  right: 0;
  bottom: calc(33px * ${(p) => p.position});
  width: max-content;

  font-size: ${rem(14)};
  line-height: ${rem(24)};
  font-weight: 500;
  z-index: 2;

  ${responsive.md`
    font-size: ${rem(16)};
    line-height: ${rem(26)};
  `};

  &::after {
    content: "";
    position: absolute;
    top: 100%;
    right: 24px;
    width: 1px;
    background-color: ${rgba(Color.ritualBlue, 0.16)};
    height: calc(33px * ${(p) => p.position});

    ${responsive.md`
      right: 40px;
    `};
  }

  &.highlight {
    &::before {
      content: "";
      position: absolute;
      top: calc(50% - 3px);
      right: -3px;
      left: -3px;
      height: 6px;
      background-color: #ffeeaa;
      z-index: -1;
    }
  }
`;

const Disclaimer = styled.p`
  font-size: ${rem(12)};
  line-height: ${rem(18)};
  font-weight: 300;
  color: rgba(20, 43, 111, 0.56);
  margin: 0 auto;
  max-width: 486px;

  ${responsive.md`
    font-size: ${rem(14)};
    line-height: ${rem(20)};
  `};
`;

const UsVsThem = ({ title, description, disclaimer, columns, rows }) => {
  return (
    <Container>
      <Row>
        <Column>
          <h1>{title}</h1>
          <p>{description}</p>

          <Table>
            <thead>
              <tr>
                <th>
                  <span className="sr-only">Claims</span>
                </th>
                {columns.map((column, i) => (
                  <th key={i}>
                    <ColumnLabel
                      className={i === 0 ? "highlight" : ""}
                      position={i + 1}
                    >
                      {column}
                    </ColumnLabel>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {rows.map((row, i) => {
                return (
                  <tr key={i}>
                    <td>
                      <h2>{row.title}</h2>
                      <p>{row.description}</p>
                    </td>
                    {row.columns.map((column, j) => (
                      <td
                        className={classNames({
                          true: column,
                          false: !column,
                        })}
                        key={j}
                      >
                        {column ? (
                          <Icons.UsCheck
                            aria-label={intl.t("general.yes", "Yes")}
                          />
                        ) : (
                          <Icons.ThemCross
                            aria-label={intl.t("general.no", "No")}
                          />
                        )}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </Table>

          <Disclaimer>{disclaimer}</Disclaimer>
        </Column>
      </Row>
    </Container>
  );
};

export default UsVsThem;
